<template>
	<div>
		<div class="gcse-search"></div>
	</div>
</template>

<script>
export default {
	name: "InternetSearchGlobal",
	data() {
		return {
			gSearchScript: null,
		};
	},
	mounted() {
		this.gSearchScript = document.createElement("script");
		//need to get their cse link
		this.gSearchScript.setAttribute(
			"src",
			"https://cse.google.com/cse.js?cx=012933829161448259585:dsbzjltygsy"
		);
		document.head.appendChild(this.gSearchScript);

		// Create a mutation observer to watch for changes in your DOM
		const observer = new MutationObserver(() => {
			let anchortags = document.getElementsByTagName("a");
			if (anchortags && anchortags.length > 0) {
				Array.from(anchortags).forEach((a) => {
					if (a.classList.contains("gs-title")) {
						a.setAttribute("target", "_blank");
					}
				});
			}
		});

		// Start observing the document with the configured parameters
		observer.observe(document.body, { childList: true, subtree: true });
	},
	destroyed() {
		//remove the global gsearch script and element from the dom
		document.head.removeChild(this.gSearchScript);
	},
};
</script>
