<template>
	<div class="gsearch">
		<h3 class="gsearch-heading mt-2 mb-2" style="margin-left: 10px">
			<span :class="!globalSearch ? 'active' : null" @click="globalSearch = false">North America</span>
			|
			<span :class="globalSearch ? 'active' : null" @click="globalSearch = true">Global</span>
		</h3>
		<h3 style="margin-left: 10px">{{ renderDescription }}</h3>

		<div class="gsearch-wrapper">
			<NaSearch v-if="!globalSearch" />
			<GlobalSearch v-else />
		</div>
	</div>
</template>

<script>
import NaSearch from "@/components/InternetSearch/NaSearch.vue";
import GlobalSearch from "@/components/InternetSearch/GlobalSearch.vue";
export default {
	name: "InternetSearch",
	components: {
		NaSearch,
		GlobalSearch,
	},
	data() {
		return {
			globalSearch: false,
		};
	},
	computed: {
		renderDescription() {
			return this.globalSearch
				? "Search Chemical Producer Websites Worldwide."
				: "Search North American Chemical Producer Websites.";
		},
	},
};
</script>

<style lang="scss" scoped>
.gsearch {
	&-heading {
		span {
			color: #003399;
			text-decoration: underline;
			cursor: pointer;

			&.active {
				color: $color-black;
				text-decoration: none;
				cursor: initial;
			}
		}
	}

	&-wrapper {
		position: relative;
		display: block;
		padding: 13px;
	}
}
</style>

<style lang="scss">
table.gsc-search-box td {
	line-height: initial;
}
</style>
